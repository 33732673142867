import {useQuery} from "@apollo/client";
import {GET_TIMEZONES} from "./commands";

/**
 * Custom hook to fetch timezones.
 * @returns {{timezones: Array<object>, loading: boolean}} - The list of timezones and loading state.
 */
export function useTimezones() {
  const {data, loading} = useQuery(GET_TIMEZONES);

  return {
    timezones: data?.mongoTimezones || [],
    loading
  };
}
