import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {REDIRECT_STATS} from "./commands";

/**
 * Custom hook to fetch redirect stats.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{redirectStats: Function, loading: boolean}} - The function to fetch redirect stats and the loading state.
 */
export function useRedirectStats(onCompleted = () => {
}, onError = () => {
}) {
  const [comRedirectStats, {loading}] = useMutation(REDIRECT_STATS, {
    onCompleted: ({comRedirectStats}) => {
      if (comRedirectStats.success) {
        onCompleted(comRedirectStats.result);
      }
    },
    onError
  });

  const redirectStats = useCallback(
    async variables => {
      await comRedirectStats({
        variables
      });
    },
    [comRedirectStats]
  );

  return {
    redirectStats,
    loading
  };
}
