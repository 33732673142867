import React, {createContext, useContext, useState} from "react";
import {TextDomainContext} from "@absolutesight/react-gettext";
import PropTypes from 'prop-types';

const UserContext = createContext({
  user: null,
  setUser: () => null,
  colors: [],
  setColors: () => null
});

// export const withUser = Component => {
//   return props => <Component {...props} {...useContext(UserContext)} />;
// };

/**
 * Higher Order Component to provide user context.
 * @param {React.ComponentType} Component - The component to wrap with user context.
 * @returns {React.ComponentType} - The component wrapped with user context.
 */
export const withUser = Component => {
  const WithUserComponent = props => <Component {...props} {...useContext(UserContext)} />;
  WithUserComponent.displayName = 'WithUser';
  return WithUserComponent;
};

/**
 * Custom hook to access user context.
 * @returns {{user: *, setUser: Function, colors: Array, setColors: Function}} - The user context.
 */
export function useUser() {
  const {user, setUser, colors, setColors} = useContext(UserContext);
  return {
    user,
    setUser,
    colors,
    setColors
  };
}

/**
 * Custom hook to access text domain context.
 * @returns {{TextDomainContext: React.Context}} - The text domain context.
 */
export function useTextDomainContext() {
  return {
    TextDomainContext
  };
}

/**
 * UserProvider component to provide user context to its children.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactElement} - The UserProvider component.
 */
export default function UserProvider({children}) {
  const [user, setUser] = useState(undefined);
  const [colors, setColors] = useState([]);

  const value = {
    user,
    setUser,
    colors,
    setColors
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};