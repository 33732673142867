import {merge} from "lodash";
import {ComponentsOverrides as CommonComponentsOverrides} from "../../common";
// import Alert from "./Alert";
// import AppBar from "./AppBar";
// import Button from "./Button";
// import Chip from "./Chip";
// import InputLabel from "./InputLabel";
// import OutlinedInput from "./OutlinedInput";
// import Drawer from "./Drawer";
// import Toolbar from "./Toolbar";
// import Link from "./Link";

/**
 * Generates component overrides for the theme.
 * @param {object} theme - The theme object.
 * @returns {object} - The merged component overrides.
 */
export const ComponentsOverrides = (theme) => {
  return merge(
    CommonComponentsOverrides(theme)
    // Alert(theme),
    // AppBar(theme),
    // Button(theme),
    // Chip(theme),
    // Drawer(theme),
    // InputLabel(theme),
    // Link(theme),
    // OutlinedInput(theme),
    // Toolbar(theme)
  );
};
