import {formatDateDayMonthYearTime} from "./formatDateDayMonthYearTime";
import {formatDateMonthYear} from "./formatDateMonthYear";
import {formatDateDayMonthYear} from "./formatDateDayMonthYear";

export const formatDateBySegment = (date, segment) => {
  let returnDate = "";

  switch (segment) {
    case "year":
    case "m":
      returnDate = formatDateMonthYear(date);
      break;
    case "month":
    case "d":
      returnDate = formatDateDayMonthYear(date);
      break;
    case "day":
    case "h":
      returnDate = formatDateDayMonthYearTime(date);
      break;
    default:
      returnDate = "";
  }

  return returnDate;
};