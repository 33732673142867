import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext} from "../../providers";
import {useAddUserByAdmin, useCountries, useRolesList, useTimezones, useUpdateUser} from "../../../hooks";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {usePartnersList} from "../../../hooks/partners/usePartnersList";
import {isEqual} from "lodash";
import {languageList} from "../../../utils";

export const AddUser = (props) => {
  //region Loading data
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const {
    countries,
    loading: countriesLoading
  } = useCountries();
  const {
    timezones,
    loading: timezonesLoading
  } = useTimezones();
  const {rolesList} = useRolesList();
  const {partnerList} = usePartnersList();
  const languages = languageList(gettext);

  const initialValue = useMemo(() => {
    return {
      userId: "",
      timezonesList: [],
      passwordShown: false,
      inputName: "",
      inputEmail: "",
      inputLang: languages[0] || null,
      inputCountry: countries[0] || null,
      inputTimezone: timezones[0] || null,
      inputStatus: {
        label: gettext("Active"),
        value: "Active"
      },
      inputRole: rolesList[rolesList.length - 1] || null,
      inputPartner: partnerList[0] || null,
      inputPassword: ""
    };
  }, [countries, gettext, languages, partnerList, rolesList, timezones]);
  //endregion Loading data

  //region React Hook (useState)
  const [userId, setUserId] = useState(initialValue.userId);
  const [timezonesList, setTimezonesList] = useState(initialValue.timezonesList);
  const [passwordShown, setPasswordShown] = useState(initialValue.passwordShown);
  const [inputName, setInputName] = useState(initialValue.inputName);
  const [inputEmail, setInputEmail] = useState(initialValue.inputEmail);
  const [inputLang, setInputLang] = useState(initialValue.inputLang);
  const [inputCountry, setInputCountry] = useState(initialValue.inputCountry);
  const [inputTimezone, setInputTimezone] = useState(initialValue.inputTimezone);
  const [inputStatus, setInputStatus] = useState(initialValue.inputStatus);
  const [inputRole, setInputRole] = useState(initialValue.inputRole);
  const [inputPartner, setInputPartner] = useState(initialValue.inputPartner);
  const [inputPassword, setInputPassword] = useState(initialValue.inputPassword);
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    if (inputCountry && timezones) {
      const filteredTimezones = timezones.filter(
        timezone => timezone.countryCode === inputCountry.code
      );
      if (!isEqual(filteredTimezones, timezonesList)) {
        setTimezonesList(filteredTimezones);
        setInputTimezone(filteredTimezones[0]);
      }
    } else {
      setTimezonesList([]);
    }
  }, [inputCountry, timezones, timezonesList]);

  useEffect(() => {
    if (!inputCountry?.code) {
      if (countries.length) {
        if (!isEqual(inputCountry, countries[0])) {
          setInputCountry(countries[0]);
        }
      }
    }
  }, [countries, initialValue, inputCountry]);

  useEffect(() => {
    if (!inputPartner?.id) {
      if (partnerList.length) {
        setInputPartner(partnerList[0]);
      }
    }
  }, [partnerList, inputPartner]);

  useEffect(() => {
    if (!inputRole?.key) {
      if (rolesList.length) {
        setInputRole(rolesList[rolesList.length - 1]);
      }
    }
  }, [rolesList, inputRole]);

  useEffect(() => {
    if (props?.row?.id) {
      const countryRow = countries.find(country => country.code === props?.row?.countryCode);
      const timeZoneRow = timezonesList.find(timeZone => timeZone.zoneName === props?.row?.timezone);
      const languageRow = languages.find(language => language.lang === props?.row?.lang);
      const roleRow = rolesList.find(role => role.role === props?.row?.roles[0]);
      const partnerRow = partnerList.find(partner => partner.key === props?.row?.partner?.key);

      setUserId(props.row.id);
      setInputName(props.row.name);
      setInputEmail(props.row.email);
      setInputCountry(countryRow || null);
      setInputTimezone(timeZoneRow || null);
      setInputLang(languageRow || null);
      setInputRole(roleRow || null);
      setInputPartner(partnerRow || null);
    }
  }, [countries, languages, partnerList, props.row, rolesList, timezonesList]);

  //endregion React Hook (useEffect)

  //region Handlers
  const resetForm = useCallback(() => {
    setUserId(initialValue.userId);
    setInputName(initialValue.inputName);
    setInputEmail(initialValue.inputEmail);
    setInputPassword(initialValue.inputPassword);
  }, [initialValue]);

  const [emailError, setEmailError] = useState(false);

  const {
    addUserByAdmin,
    loading: adding
  } = useAddUserByAdmin(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("New User Added Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  const {
    updateUser,
    loading: updating
  } = useUpdateUser(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("User Updated Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(`Backend Error: ${error}`, {
          variant: "error"
        });
      }
    }
  );

  const submitHandler = e => {
    e.preventDefault();
    // addUserByAdmin({
    //   variables: {
    //     name: inputName,
    //     email: inputEmail,
    //     password: inputPassword,
    //     role: [inputRole?.key],
    //     partnerId: inputPartner?.id,
    //     countryCode: inputCountry?.code,
    //     lang: inputLang?.value,
    //     timezone: inputTimezone.zoneName,
    //     status: inputStatus.value
    //   },
    //   refetchQueries: ["comMyUsers"]
    // });

    const variables = {
      name: inputName,
      email: inputEmail,
      roles: [inputRole?.key],
      partnerId: inputPartner?.id,
      countryCode: inputCountry?.code,
      lang: inputLang?.lang,
      timezone: inputTimezone.zoneName,
      status: inputStatus.value
    };

    if (props.row.id) {
      variables.userId = props.row.id;
      updateUser({
        variables: variables,
        refetchQueries: ["AllUsers", "SystemUsers", "PartnerUsers", "CompanyUsers"]
      }).then();
    } else {
      variables.password = inputPassword;
      addUserByAdmin({
        variables: variables,
        refetchQueries: ["AllUsers", "SystemUsers", "PartnerUsers", "CompanyUsers"]
      }).then();
    }
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleNameChange = value => {
    setInputName(value);
  };

  const handleClose = () => {
    resetForm();
    props.handleClose();
  };
  //endregion Handlers

  return (
    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: {
          padding: 4,
          maxWidth: 720
        }
      }}
    >
      <Box sx={{
        width: "auto"
      }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{
            height: "100%"
          }}>
            <Typography fontSize={22} fontWeight={700}>
              {!props.row.id && gettext("Add New User")}
              {props.row.id && gettext("Edit User")}
            </Typography>

            <Box sx={{
              height: "100%"
            }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label={gettext("Name")}
                    placeholder={gettext("Enter name")}
                    size="small"
                    required
                    fullWidth
                    value={inputName}
                    onChange={e => handleNameChange(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="email"
                    label={gettext("Email")}
                    placeholder={gettext("Enter email")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="off"
                    value={inputEmail}
                    onChange={e => {
                      setInputEmail(e.target.value);
                      setEmailError(false);
                    }}
                    error={emailError}
                    helperText={emailError && gettext("Email already exists!")}
                  />
                </Grid>

                {!userId && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      type={passwordShown ? "text" : "password"}
                      label={gettext("Password")}
                      placeholder={gettext("Enter password")}
                      size="small"
                      required
                      fullWidth
                      autoComplete="new-password"
                      value={inputPassword}
                      onChange={e => setInputPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePassword}
                              edge="end"
                            >
                              {passwordShown ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={partnerList}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.id || ""}
                    value={inputPartner}
                    onChange={(e, newValue) => setInputPartner(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField {...params} required label={gettext("Partners")}/>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={rolesList}
                    getOptionLabel={option => option.role || ""}
                    getOptionKey={option => option.key || ""}
                    value={inputRole}
                    onChange={(e, newValue) => setInputRole(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField {...params} required label={gettext("Role")}/>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={languages}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.lang || ""}
                    // options={[
                    //   {
                    //     label: gettext("English"),
                    //     value: "en"
                    //   },
                    //   {
                    //     label: gettext("Danish"),
                    //     value: "da"
                    //   },
                    //   {
                    //     label: gettext("German"),
                    //     value: "de"
                    //   }
                    // ]}
                    value={inputLang}
                    onChange={(e, newValue) => setInputLang(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Language")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={option => option.name || ""}
                    getOptionKey={option => option.code || ""}
                    value={inputCountry}
                    onChange={(e, newValue) => {
                      setInputCountry(newValue);
                      // setInputTimezone(initialValue.inputTimezone);
                    }}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Country")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={timezonesList}
                    getOptionLabel={option => option.zoneName || ""}
                    getOptionKey={option => option.zoneName || ""}
                    value={inputTimezone}
                    onChange={(e, newValue) => setInputTimezone(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Timezone")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={[
                      {
                        label: gettext("Active"),
                        value: "Active"
                      },
                      {
                        label: gettext("InActive"),
                        value: "InActive"
                      },
                      {
                        label: gettext("Deleted"),
                        value: "Deleted"
                      },
                      {
                        label: gettext("Blocked"),
                        value: "Blocked"
                      }
                    ]}
                    value={inputStatus}
                    onChange={(e, newValue) => setInputStatus(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Status")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={adding || updating || countriesLoading || timezonesLoading}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

AddUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  row: PropTypes.object
};
