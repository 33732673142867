import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Box, Typography} from "@mui/material";
import {usePagePrivileges} from "../../../../hooks";
import PropTypes from "prop-types";

/**
 *
 * @param root0
 * @param root0.link
 * @param root0.icon
 * @param root0.codes
 * @param root0.label
 * @param root0.desc
 */
export default function ProfileMenuItem({
                                          link,
                                          icon,
                                          codes,
                                          label,
                                          desc
                                        }) {
  const [displayOption, setDisplayOption] = useState(false);
  const allow = usePagePrivileges(codes);

  useEffect(() => {
    if (allow) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [allow]);

  return (
    <>
      {displayOption && (
        <NavLink to={link}>
          <MenuItem sx={{
            color: "primary.main"
          }}>
            <ListItemIcon sx={{
              color: "inherit"
            }}>{icon}</ListItemIcon>
            <Box>
              <Typography
                variant="body1"
                color="grey"
                sx={{
                  fontSize: 16,
                  fontWeight: "light"
                }}
              >
                {label}
              </Typography>
              <Typography variant="caption" color="grey">
                {desc}
              </Typography>
            </Box>
          </MenuItem>
        </NavLink>
      )}
    </>
  );
}

ProfileMenuItem.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.object,
  codes: PropTypes.array,
  label: PropTypes.string,
  desc: PropTypes.string,
};