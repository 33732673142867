import {formatDateDayMonth, formatDateDayMonthTime, formatDateMonth} from "../";

export const formatDateBySegmentNoYear = (date, segment) => {
  let returnDate = "";

  switch (segment) {
    case "year":
    case "m":
      returnDate = formatDateMonth(date);
      break;
    case "month":
    case "d":
      returnDate = formatDateDayMonth(date);
      break;
    case "day":
    case "h":
      returnDate = formatDateDayMonthTime(date);
      break;
    default:
      returnDate = "";
  }

  return returnDate;
};