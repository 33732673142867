import {useCallback, useEffect, useRef} from "react";

/**
 * Custom hook to set a timeout.
 * @param {Function} callback - The callback function to be executed after the delay.
 * @param {number} delay - The delay in milliseconds.
 * @returns {void}
 */
export const useTimeout = (callback, delay) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return {
    reset,
    clear
  };
};
