import React, {forwardRef, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {AppBar as AppBarTop, IconButton} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {alpha} from "@mui/material/styles";
import styled from "@mui/styled-engine";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import ProfileMenu from "./profileMenu";
import {useTextDomainContext, useUser} from "../../providers";
import {useQuery} from "@apollo/client";
import {GET_NEW_NOTIFICATIONS} from "./commands";
import dateFormat from "dateformat";
import {LanguageSelector} from "../LanguageSelector";
import PropTypes from "prop-types";

// import { OverlayTrigger } from "react-bootstrap";
// import LanguageSelector from "./LanguageSelector";

const Search = styled("div")(({theme}) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

export const AppBar = forwardRef((props, ref) => {
  const [notificationData, setNotificationData] = useState([]);

  const [showNotificationCounter, setNotificationCounter] = useState(true);

  const [sortedNotifications, setSortedNotifications] = useState([]);

  const {
    data,
    loading
  } = useQuery(GET_NEW_NOTIFICATIONS);

  const {user} = useUser();

  const {profilePhotoUrl} = user || {};

  const {isRegistration} = props;

  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const navigate = useNavigate();
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    if (loading) {
      return;
    }
    setNotificationData(data ? data?.comGetNewNotifications : []);
    setSortedNotifications(notificationData && notificationData?.slice(0, 10));
    profilePhotoUrl && setPhoto(profilePhotoUrl);
  }, [profilePhotoUrl, data, notificationData, loading]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notification, setNotification] = useState(null);
  const openNotification = Boolean(notification);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenNotification = event => {
    setNotification(event.currentTarget);
    setNotificationCounter(false);
  };
  const handleCloseNotification = () => {
    setNotification(null);
  };
  return (
    <AppBarTop
      ref={ref}
      position={isRegistration ? "static" : "fixed"}
      color="secondary"
    >
      <Toolbar>
        <Box sx={{
          width: 220
        }}>
          <Box sx={{
            minWidth: "100px",
            width: "150px",
            paddingY: 2
          }}>
            <Link to="">
              <img alt="logo" src={"/media/logos/energiteam-logo.png-1"}/>
            </Link>
          </Box>
        </Box>

        <Search>
          <SearchIconWrapper>
            <SearchIcon/>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={gettext("Search...")}
            inputProps={{
              "aria-label": "search"
            }}
          />
        </Search>

        <Box sx={{
          flexGrow: 1
        }}/>

        {!isRegistration && (
          <Box>
            <Box
              marginRight={2}
              onClick={handleOpenNotification}
              marginBottom={showNotificationCounter && "20px"}
            >
              {showNotificationCounter && (
                <Box
                  width={30}
                  height={20}
                  sx={{
                    backgroundColor: "#DE350B"
                  }}
                  textAlign="center"
                  borderRadius={8}
                  position="relative"
                  top={8}
                >
                  <p style={{
                    color: "#fff",
                    fontSize: "12px"
                  }}>
                    {data?.comGetNewNotifications.length > 10
                      ? "9+"
                      : data?.comGetNewNotifications.length <= 0
                        ? setNotificationCounter(false)
                        : data?.comGetNewNotifications.length}
                  </p>
                </Box>
              )}
              {/*<OverlayTrigger*/}
              {/*  overlay={*/}
              {/*    <Tooltip>*/}
              {/*      <strong>{gettext("Notifications")}</strong>*/}
              {/*    </Tooltip>*/}
              {/*  }*/}
              {/*>*/}
              {/*  <NotificationsIcon*/}
              {/*    sx={{*/}
              {/*      fontSize: 25,*/}
              {/*      cursor: "pointer",*/}
              {/*      transform: "rotate(50deg)"*/}
              {/*    }}*/}
              {/*    color="success"*/}
              {/*  />*/}
              {/*</OverlayTrigger>*/}
            </Box>
          </Box>
        )}

        <Box>
          <Menu
            anchorEl={notification}
            id="account-menu"
            open={openNotification}
            onClose={handleCloseNotification}
            onClick={handleCloseNotification}
            PaperProps={{
              style: {
                width: 400,
                maxHeight: 400,
                overflowY: "scroll"
              },
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 0,
                "& .MuiAvatar-root": {
                  width: 82,
                  height: 82,
                  ml: 0,
                  mr: 0
                },
                "&:before": {
                  content: "\"\"",
                  display: "block",
                  position: "absolute",
                  top: 5,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top"
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom"
            }}
          >
            <Box
              height={60}
              borderBottom={0.5}
              className="px-4 d-flex align-items-center"
            >
              <Typography fontSize={22} fontWeight={700}>
                {gettext("Notifications")}
              </Typography>
              <Tooltip
                title={gettext("See All Notifications")}
                arrow
                style={{
                  marginLeft: "auto"
                }}
              >
                <IconButton onClick={() => navigate("/notifications")}>
                  <LaunchIcon size={16}/>
                </IconButton>
              </Tooltip>
            </Box>
            {sortedNotifications?.map(notify => {
              return (
                <Box key={notify?.id}>
                  <MenuItem
                    sx={{
                      color: "primary.main"
                    }}
                    onClick={() => navigate("/notifications")}
                  >
                    <ListItemIcon
                      sx={{
                        color: "inherit"
                      }}
                      style={{
                        marginBottom: 62
                      }}
                    >
                      <EmailIcon fontSize="medium"/>
                    </ListItemIcon>
                    <Box component="div" whiteSpace="normal">
                      <Typography
                        variant="body1"
                        color="grey"
                        sx={{
                          fontSize: 16,
                          fontWeight: "light"
                        }}
                        textOverflow="hidden"
                      >
                        {notify?.Message}
                      </Typography>
                      <Typography variant="caption" color="grey">
                        {dateFormat(
                          notify?.date,
                          "dddd, mmm dS, yyyy, h:MM:ss TT"
                        )}
                      </Typography>
                    </Box>
                  </MenuItem>
                  <Divider/>
                </Box>
              );
            })}
            <Divider/>
          </Menu>
        </Box>

        {/* <Box>
          <Box marginRight={2} onClick={() => navigate("/notifications")}>
            <NotificationsIcon
              sx={{ fontSize: 30, cursor: "pointer" }}
              color="success"
            />
          </Box>
        </Box> */}

        <Box>
          <Link to={"/user-profile/customers"}>
            {user?.currentTeam === "null" ? (
              gettext("Select Company")
            ) : (
              <>
                {user?.currentTeam}
                {/* {!currentUser?.teamRights?.write && (
                  gettext('(Read Only)')
                )} */}
              </>
            )}
          </Link>
        </Box>

        <Box>
          <LanguageSelector/>
        </Box>

        <Box>
          <Tooltip title="Account settings">
            <ListItemButton onClick={handleClick}>
              <Avatar alt="" src={photo}/>

              <div className="d-flex flex-column mx-2 text-left">
                <Typography
                  variant="body1"
                  color="grey"
                  className="font-weight-bold"
                >
                  {user?.name}
                </Typography>

                <Typography variant="subtitle2" color="inherit">
                  {user?.email}
                </Typography>
              </div>
            </ListItemButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            style: {
              width: 400
            },
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              "& .MuiAvatar-root": {
                width: 82,
                height: 82,
                ml: 0,
                mr: 0
              },
              "&:before": {
                content: "\"\"",
                display: "block",
                position: "absolute",
                top: 0,
                right: 100,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top"
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom"
          }}
        >
          <MenuItem
            sx={{
              height: 150,
              // width: 330,
              overflow: "auto",
              cursor: "auto",
              background: "#F5F7F9",
              display: isRegistration && "none"
            }}
          >
            <div className="d-flex flex-row ">
              <Toolbar>
                <Box>
                  <Avatar alt="avatar" src={photo}/>
                </Box>
              </Toolbar>

              <Box>
                <Typography
                  variant="h5"
                  color="grey"
                  className="font-weight-bold"
                >
                  {user?.name}
                </Typography>
                <div className="d-flex flex-wrap">
                  {user?.roles?.map((role) => (
                    <Chip
                      className="m-1"
                      variant="outlined"
                      size="small"
                      label={role}
                      key={`Chip-${role}`}
                    />
                  ))}
                </div>

                <Box>
                  <ListItemIcon>
                    <EmailIcon fontSize="small"/>
                  </ListItemIcon>
                  {user?.email}
                </Box>

                <Box>
                  <ListItemIcon>
                    <LocationOnIcon fontSize="small"/>
                  </ListItemIcon>
                  {user?.countryCode}
                </Box>
              </Box>
            </div>
          </MenuItem>

          {!isRegistration && <Divider/>}

          <ProfileMenu isRegistration={isRegistration}/>
        </Menu>
      </Toolbar>
    </AppBarTop>
  );
});

AppBar.displayName = "AppBar";

AppBar.propTypes = {
  isRegistration: PropTypes.bool,
};