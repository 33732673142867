import {TableView1} from "../";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useLang, useTextDomainContext, useUser} from "../../providers";
import * as PropsType from "prop-types";
import {energyStatementKey, indexedDbGetOnePageData} from "../../../utils";
import {yearColumns} from "../../../utils/export/energyStatement";

export const EnergyYearStatement = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const locale = useLang();
  const columns = useMemo(() => {
    return yearColumns(gettext, locale);
  }, [gettext, locale]);
  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {};

  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  //#region React Hooks (useState)

  const propsDefault = {
    title: gettext("Annual energy consumption (kWh)"),
    rowCount: -1,
  };
  const [fileNameForExport, setFileNameForExport] = useState("energy-overall-statement");
  const [title, setTitle] = useState(propsDefault.title);
  const [loading, setLoading] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [rows, setRows] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [rowCount, setRowCount] = useState(propsDefault.rowCount);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState({
    items: []
  });
  const [sortModel, setSortModel] = useState([]);
  const [filters, setFilters] = useState({});
  const [initialState, setInitialState] = useState({
    pagination: {
      paginationModel
    }
  });
  const [infoText, setInfoText] = useState("");

  //#endregion React Hooks (useState)

  //#region React Hooks (useEffect)

  useEffect(() => {
    setInfoText(props.infoText);
  }, [props.infoText]);

  useEffect(() => {
    setFilters({
      meterType: props.meterType,
      customer: customer,
      group: group,
      year: props.year,
      isClimate: props.isClimate,
    });
  }, [customer, group, props.isClimate, props.meterType, props.year]);

  useEffect(() => {
    setFileNameForExport(props.fileNameForExport);
  }, [props.fileNameForExport]);

  // useEffect(() => {
  //   setColumns(varYearColumns);
  // }, [varYearColumns]);

  useEffect(() => {
    if (props.isClimate) {
      setTitle(gettext("Annual CO₂e (Kg)"));
    } else {
      setTitle(propsDefault.title);
    }
  }, [gettext, props.isClimate, propsDefault.title]);

  useEffect(() => {
    setLoading(props.loading);
    setLocalLoading(true);
    if (filters?.meterType) {
      const key = energyStatementKey("aggregated", filters);
      indexedDbGetOnePageData("cache", key, paginationModel, sortModel, filterModel).then((result) => {
        if (result?.data?.length) {
          setRowCount(result.rowCount);
          setRows(result.data);
        } else if (!props.loading) {
          if (result?.data) {
            setRowCount(result.rowCount);
            setRows(result.data);
          } else {
            setRowCount(0);
            setRows([]);
          }
        }
        setLocalLoading(false);
      }).catch();
    }
  }, [filterModel, filters, paginationModel, props.loading, sortModel]);

  useEffect(() => {
    setInitialState({
      pagination: {
        paginationModel
      }
    });
  }, [paginationModel]);

  //#endregion React Hooks (useEffect)

  //#region Handlers

  const handleChangeEnergyStatement = (obj) => {
    if (typeof props.onChangeEnergyStatement === "function") {
      props.onChangeEnergyStatement(obj);
    }
  };

  //#endregion Handlers

  return (
    <>
      <TableView1
        title={title}
        rows={rows}
        // rows={[]}
        rowCount={rowCount}
        columns={columns}
        loading={loading || localLoading}
        initialState={initialState}
        showGridToolbar
        showGridToolbarEnergyStatementExport
        // showGridToolbarQuickFilter
        fileNameForExport={fileNameForExport}
        excelData={props.excelData}
        onChangeEnergyStatement={handleChangeEnergyStatement}
        dataMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        infoText={infoText}
      />
    </>
  );
};

EnergyYearStatement.propTypes = {
  onChangeEnergyStatement: PropsType.func,
  fileNameForExport: PropsType.string,
  excelData: PropsType.array,
  // rowCount: PropsType.number,
  meterType: PropsType.string.isRequired,
  year: PropsType.number.isRequired,
  // columns: PropsType.array.isRequired,
  loading: PropsType.bool,
  infoText: PropsType.string,
  isClimate: PropsType.bool,
  // rows: PropsType.array,
};

