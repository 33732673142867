import moment from "moment/moment";
import {capitalizeFirstLetter} from "../string";

export const formatDateDayMonthYear = (date) => {
  // return capitalizeFirstLetter(moment(date).format("DD MMM YYYY"));

  const formattedDate = moment(date).format("DD MMM YYYY");
  const [day, month, year] = formattedDate.split(" ");
  const capitalizedMonth = capitalizeFirstLetter(month);
  return `${day} ${capitalizedMonth} ${year}`;
};
