export const chart = {
  electricity: "#FFBF00",
  gas: "#ED7D31",
  lpg: "#4472C4",
  oil: "#7030A0",
  energy: "#FA435C",
  production: "#30BC7C",
  water: "#004bff",
  heating: "#dc562b",
  co2: "#c0c7b5",
  year1: "#FFBF00",
  year2: "#ED7D31",
};
