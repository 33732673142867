import React, {useCallback, useContext, useEffect, useState} from "react";
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import {useDropzone} from "react-dropzone";
import PropTypes from "prop-types";
import {TbFileUpload} from "react-icons/tb";
import {CiCircleInfo} from "react-icons/ci";
import {FileIcon} from "../Images";
import {RxCross2} from "react-icons/rx";
import {formatFileSize} from "../../../utils";

/**
 * A file dropzone. It will use to select files for upload.
 * @param props
 * @returns {React.JSX.Element}
 * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types Helping link for MIME type
 * @class
 */
export const Dropzone2 = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  //region React Hooks (useState)
  const propsDefault = {
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
    }
  };

  const [progress, setProgress] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [selectedFiles, setSelectedFiles] = useState(FilesObject);
  const [highlighted, setHighlighted] = useState(false);
  const [accept, setAccept] = useState(props.accept || propsDefault.accept);
  const [boxHeight, setBoxHeight] = useState("100%");
  // const [boxHeight, setBoxHeight] = useState("150px");
  //endregion React Hooks (useState)

  //region React Hooks (useEffect)
  useEffect(() => {
    if (typeof props.accept !== "undefined") {
      setAccept(props.accept);
    }
  }, [props.accept]);

  useEffect(() => {
    if (typeof props.progress !== "undefined") {
      setProgress(props.progress);
    }
  }, [props.progress]);

  const {getSelected} = props;
  useEffect(() => {
    if (typeof getSelected === "function") {
      getSelected(selectedFiles);
    }
  }, [getSelected, selectedFiles]);
  // useEffect(() => {
  //   if (typeof props.getSelected === "function") {
  //     props.getSelected(selectedFiles);
  //   }
  // }, [props, selectedFiles]);
  //endregion React Hooks (useEffect)

  //region Handlers
  const onDrop = useCallback(
    acceptedFiles => {
      const allMergedFiles = [...selectedFiles, ...acceptedFiles];

      const allSelectedFiles = allMergedFiles.filter((file, index, self) =>
        index === self.findIndex((f) => f.path === file.path)
      );

      if (allSelectedFiles.length) {
        setBoxHeight("150px");
      } else {
        setBoxHeight("100%");
      }
      setSelectedFiles(allSelectedFiles);
    }, [selectedFiles]);

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: accept
  });

  const handleDelete = (file) => {
    const allSelectedFiles = selectedFiles.filter((fileObj) => fileObj !== file);
    if (allSelectedFiles.length) {
      setBoxHeight("150px");
    } else {
      setBoxHeight("100%");
    }
    setSelectedFiles(allSelectedFiles);
    //TODO:  Following two lines is only for removing warnings. As soon as we will add it in other side of the code, we can remove them
    // setLoading(false);
    // setProgress(null);
  };
  //endregion Handlers

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          p: 1,
          backgroundColor: "grey.50",
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
          minHeight: "52px",
          borderRadius: "3px",
          cursor: "pointer",
          height: boxHeight,
        }}

        {...getRootProps({
          className: `p-16 my-10 border text-center ${
            highlighted ? "border-primary" : "border-secondary "
          }`
        })}
        onDragEnter={() => {
          setHighlighted(true);
        }}
        onDragLeave={() => {
          setHighlighted(false);
        }}
      >
        <TbFileUpload size={46}/>
        <Typography
          fontSize={14}
          fontWeight={400}
        >
          <input {...getInputProps()} />
          {gettext("Click to upload or drag and drop")}<br/>
          <CiCircleInfo size={12}/>&nbsp;
          {gettext("Max size supported 20MB")}
        </Typography>

      </Box>

      {selectedFiles?.length > 0 && (<Box sx={{
        textAlign: "left",
      }}>
        <Typography fontWeight={500} key={Math.random()} sx={{
          lineHeight: "40px",
        }}>
          {gettext("Uploading")}&nbsp;
          {selectedFiles?.length}&nbsp;
          {selectedFiles?.length === 1 && gettext("Item")}
          {selectedFiles?.length > 1 && gettext("Items")}
        </Typography>
      </Box>)}
      <Box
        sx={{
          p: 1,
          minHeight: "52px",
          overflow: "auto",
          height: `calc(100% - ${boxHeight} - 30px)`,
          textAlign: "left",
          scrollbarWidth: "thin",
        }}
      >
        <Box sx={{
          width: "100%",
          height: "10%",
        }}>
          {selectedFiles.map((file, index) => (
            <Box
              key={file.path}
              sx={{
                borderRadius: 1,
                border: "2px solid",
                borderColor: "grey.200",
                backgroundColor: "common.white",
                mb: 2,
                display: "flex",
                fontSize: "0.75rem",
                maxWidth: "100%",
                overflow: "hidden",
              }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  p: 2,
                  width: 64,
                }}>
                <FileIcon type={file.type} filename={file.path}/>
              </Box>
              <Box sx={{
                py: 1,
                px: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
              }}>
                <Grid container spacing={0}>
                  <Grid item xs={11} title={file.path} sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                    {file.path}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    onClick={handleDelete.bind(this, file)}
                    sx={{
                      textAlign: "right",
                      cursor: "pointer",
                    }}
                  >
                    <RxCross2 size={16}/>
                  </Grid>
                  <Grid item xs={10}>
                    {formatFileSize(file.size)}
                  </Grid>
                  <Grid item xs={2} sx={{
                    textAlign: "right",
                  }}>
                    {progress[index] ? progress[index] : 0}%
                  </Grid>
                  <Grid item xs={12} sx={{
                    py: 1,
                  }}>
                    <LinearProgress
                      variant={"determinate"}
                      value={progress[index] ? progress[index] : 0}
                      color="success"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

Dropzone2.propTypes = {
  accept: PropTypes.object,
  progress: PropTypes.array,
  getSelected: PropTypes.func
};
