import React, {useContext, useState} from "react";
import {useTextDomainContext, useUser} from "../../providers";
import {Link} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {useFormik} from "formik";
import * as Yup from "yup";
import Icon from "@mdi/react";
import SVG from "react-inlinesvg";
import {mdiEyeOffOutline, mdiEyeOutline} from "@mdi/js";
import {CHANGE_PASSWORD_MUTATIION} from "../../../GraphQL/Mutations";
import {toAbsoluteUrl} from "../../../utils";

export const ChangePassword = () => {
  // Fields
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [verifyPasswordShown, setVerifyPasswordShown] = useState(false);
  // const [loading, setloading] = useState(false);
  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const {user} = useUser();
  const {demo} = user;

  // const intl = useIntl();
  // const currentPasswordPlaceholder = intl.formatMessage({
  //   id: "AUTH.INPUT.CURRENT_PASSWORD"
  // });
  // const newPasswordPlaceholder = intl.formatMessage({
  //   id: "AUTH.INPUT.NEW_PASSWORD"
  // });
  // const verifyPasswordPlaceholder = intl.formatMessage({
  //   id: "AUTH.INPUT.VERIFY_PASSWORD"
  // });
  // const currentPasswordError = intl.formatMessage({
  //   id: "AUTH.INPUT.CURRENT_PASSWORD.ERROR"
  // });
  // const newPasswordError = intl.formatMessage({
  //   id: "AUTH.INPUT.NEW_PASSWORD.ERROR"
  // });
  // const verifyPasswordError = intl.formatMessage({
  //   id: "AUTH.INPUT.VERIFY_PASSWORD.ERROR"
  // });
  // const verifyNewPasswordError = intl.formatMessage({
  //   id: "AUTH.INPUT.NEW.VERIFY_PASSWORD.ERROR"
  // });

  const currentPasswordPlaceholder = "AUTH.INPUT.CURRENT_PASSWORD";
  const newPasswordPlaceholder = "AUTH.INPUT.NEW_PASSWORD";
  const verifyPasswordPlaceholder = "AUTH.INPUT.VERIFY_PASSWORD";
  const currentPasswordError = "AUTH.INPUT.CURRENT_PASSWORD.ERROR";
  const newPasswordError = "AUTH.INPUT.NEW_PASSWORD.ERROR";
  const verifyPasswordError = "AUTH.INPUT.VERIFY_PASSWORD.ERROR";
  const verifyNewPasswordError = "AUTH.INPUT.NEW.VERIFY_PASSWORD.ERROR";

  // UI Helpers
  const initialValues = {
    currentPassword: "",
    password: "",
    cPassword: ""
  };
  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required(currentPasswordError),
    password: Yup.string().required(newPasswordError),
    cPassword: Yup.string()
      .required(verifyPasswordError)
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], verifyNewPasswordError)
      })
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATIION, {
    onCompleted() {
    },
    onError() {
    }
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // saveUser(values, setStatus, setSubmitting);
      // setloading(true);
      changePassword({
        variables: {
          currentPassword: values.currentPassword,
          newPassword: values.password
        }
      })
        .then(res => {
          if (res.data && res.data.comUpdatePassword.success === "true") {
            setStatus(gettext("Password has been reset successfully."));
            setisError(false);
            setisSuccess(true);
            setSubmitting(false);
            // setloading(false);
          } else {
            setStatus(gettext("Wrong password!"));
            setisError(true);
            setisSuccess(false);
            setSubmitting(false);
            // setloading(false);
          }
        })
        .catch(() => {
          setisError(true);
          setisSuccess(false);
          setSubmitting(false);
          // setloading(false);
          //setStatus(err.data.message);
        });
    },
    onReset: (values, {resetForm}) => {
      resetForm();
    }
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const toggleVerifyPassword = () => {
    setVerifyPasswordShown(prev => !prev);
  };

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {/* {loading && <ModalProgressBar />} */}

      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Change Password")}</h3>
            {/* <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div> */}
            <span className="font-size-sm w-100">
              {gettext("You can change your password here")}
            </span>
          </div>
          {/* end::Header */}
          {/* begin::Form */}
          <div className="form">
            <div className="card-body">
              {/* begin::Alert */}
              {isError && (
                <div
                  className="alert alert-custom alert-light-danger fade show mb-10"
                  role="alert"
                >
                  <div className="alert-icon">
                    <span className="svg-icon svg-icon-3x svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      ></SVG>{" "}
                    </span>
                  </div>
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                  <div
                    className="alert-close"
                    onClick={() => setisError(false)}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="ki ki-close"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {isSuccess && (
                <div
                  className="alert alert-custom alert-light-success fade show mb-10"
                  role="alert"
                >
                  <div className="alert-icon">
                    <span className="svg-icon svg-icon-3x svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      ></SVG>{" "}
                    </span>
                  </div>
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                  <div
                    className="alert-close"
                    onClick={() => setisSuccess(false)}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="ki ki-close"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {/* end::Alert */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {gettext("Current Password")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder={currentPasswordPlaceholder}
                    className={`change-password form-control form-control-lg form-control-solid mb-2 bg-grey-200 text-grey-600 ${getInputClasses(
                      "currentPassword"
                    )}`}
                    name="currentPassword"
                    {...formik.getFieldProps("currentPassword")}
                  />
                  <button
                    type="button"
                    className="show-hide-password"
                    onClick={togglePassword}
                  >
                    <Icon
                      path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                      title="Password"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </button>
                  {formik.touched.currentPassword &&
                  formik.errors.currentPassword ? (
                    <div className="invalid-feedback">
                      {formik.errors.currentPassword}
                    </div>
                  ) : null}
                  {/*<a href="#" className="text-sm font-weight-bold">*/}
                  {/*  Forgot password ?*/}
                  {/*</a>*/}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {gettext("New Password")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={newPasswordShown ? "text" : "password"}
                    placeholder={newPasswordPlaceholder}
                    className={`change-password form-control form-control-lg form-control-solid bg-grey-200 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  <button
                    type="button"
                    className="show-hide-password"
                    onClick={toggleNewPassword}
                  >
                    <Icon
                      path={newPasswordShown ? mdiEyeOutline : mdiEyeOffOutline}
                      title="Password"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </button>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {gettext("Verify Password")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={verifyPasswordShown ? "text" : "password"}
                    placeholder={verifyPasswordPlaceholder}
                    className={`change-password form-control form-control-lg form-control-solid bg-grey-200 ${getInputClasses(
                      "cPassword"
                    )}`}
                    name="cPassword"
                    {...formik.getFieldProps("cPassword")}
                  />
                  <button
                    type="button"
                    className="show-hide-password"
                    onClick={toggleVerifyPassword}
                  >
                    <Icon
                      path={
                        verifyPasswordShown ? mdiEyeOutline : mdiEyeOffOutline
                      }
                      title="Password"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </button>
                  {formik.touched.cPassword && formik.errors.cPassword ? (
                    <div className="invalid-feedback">
                      {formik.errors.cPassword}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* end::Form */}
        </div>

        {/* start footer */}
        <div className="table-footer table-actions-alignment">
          <div className="col-12 p-0">
            <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting ||
                  (formik.touched && !formik.isValid) ||
                  demo
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

