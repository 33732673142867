import {Grid, Stack, Typography} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import React, {useContext} from "react";
import {AuthPageLayout} from "./components";
import {LoginForm} from "../../components";

/**
 *
 */
export function Login() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  return (
    <AuthPageLayout>
      <Grid item xs={12}>
        <Typography variant="h2" color={"grey.900"}>
          {gettext("Sign in")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="baseline"
          sx={{
            mb: {
              xs: -0.5,
              sm: 0.5
            }
          }}
        >
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <LoginForm/>
      </Grid>
    </AuthPageLayout>
  );
}
