import {getMonthAbbreviation, getMonthNameFromNumber} from "./";

export const getMonthsArray = (gettext) => {
  let monthArray = [];
  monthArray.push({
    "value": "all",
    "label": gettext("Full Year")
  });
  for (let monthNumber = 1; monthNumber <= 12; monthNumber++) {
    const monthName = getMonthNameFromNumber(monthNumber);
    const monthAbbreviation = getMonthAbbreviation(monthNumber);
    if (monthAbbreviation) {
      monthArray.push(
        {
          "value": monthAbbreviation,
          "label": monthName
        }
      );
    }
  }
  return monthArray;
};