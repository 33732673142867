import {useQuery} from "@apollo/client";
import {CUSTOMERS} from "./commands";

/**
 * Custom hook to fetch companies based on user and customer IDs.
 * @param {object} variables - The variables for the query.
 * @param {string} [variables.userId] - The ID of the user.
 * @param {string} [variables.customerId] - The ID of the customer.
 * @returns {{customers: Array<object>, loading: boolean}} - The list of customers and loading state.
 */
export function useCompanies(variables) {
  const userId = variables?.userId || null;
  const customerId = variables?.customerId || null;
  const {
    data,
    loading
  } = useQuery(CUSTOMERS, {
    variables: {
      userId,
      customerId
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  return {
    customers: data?.getCustomers || [],
    loading
  };
}
