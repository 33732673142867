import {Container, Grid, Stack, Typography} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AuthPageLayout} from "./components";
import {RegistrationForm} from "../../components";
import {getPartnerKey} from "../../../utils";
import {Navigate} from "react-router";

/**
 *
 */
export function Register() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const partnerKey = getPartnerKey();

  return (
    <AuthPageLayout>
      <Navigate to="/auth/login"/>
      <Container maxWidth="xs">
        {/*{partnerKey !== "fabeke" && (*/}
        {!partnerKey && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" color={"grey.900"}>
                {gettext("Sign up")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color={"grey.900"}>
                {gettext("This is a heads-up, everyone! We've temporarily paused new registrations. But don't worry, we'll open them again shortly. Stay tuned!")}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/*{partnerKey === "fabeke" && (*/}
        {partnerKey && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Typography fontSize={36} fontWeight={700} color="#27272A"> */}
              <Typography variant="h2" color={"grey.900"}>
                {gettext("Sign up")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RegistrationForm/>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="center"
                sx={{
                  mt: {
                    xl: 4
                  },
                  mb: {
                    xs: -0.5,
                    sm: 0.5
                  }
                }}
              >
                {/* <Typography fontSize={16} fontWeight={500} color="#3F3F46"> */}
                <Typography variant="body2">
                  {gettext("Already have an account?")}
                </Typography>
                <Typography fontSize={16} fontWeight={500}>
                  &nbsp;
                </Typography>
                <Typography
                  component={Link}
                  to="/auth/login"
                  fontSize={16}
                  fontWeight={500}
                  sx={{
                    textDecoration: "none"
                  }}
                  color="primary"
                >
                  {gettext("Sign in.")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Container>
    </AuthPageLayout>
  );
}
