import {useQuery} from "@apollo/client";
import {GET_COUNTRIES} from "./commands";

/**
 * Custom hook to fetch countries.
 * @returns {{countries: Array<object>, loading: boolean}} - The list of countries and loading state.
 */
export function useCountries() {
  const {data, loading} = useQuery(GET_COUNTRIES);

  return {
    countries: data?.mongoCountries || [],
    loading
  };
}
