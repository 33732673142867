import {Stack} from "@mui/system";
import {Checkbox, FormControlLabel, FormHelperText, Grid} from "@mui/material";
import React, {useContext, useState} from "react";
import {useTextDomainContext} from "../../providers";
import {useChangePassword} from "../../../hooks";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import PropTypes from "prop-types";
import {PasswordInput} from "../FormFields";
import {ForgotPasswordLink, PasswordValidator} from "./";

export const ChangePasswordForm = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const {
    changePassword,
    loading
  } = useChangePassword(
    () => {
      handleClose();
      enqueueSnackbar(gettext("Your password has been changed"), {
        variant: "success"
      });
    },
    error => {
      enqueueSnackbar(error?.message, {
        variant: "error"
      });
    }
  );

  //region React Hook (useState)
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [passwordValid, setPasswordValid] = useState(false);
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    if (confirmPassword && confirmPassword !== event.target.value) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }

    if ((confirmPassword && confirmPassword !== event.target.value) || !passwordValid) {
      setSaveButtonDisabled(true);
    } else {
      if (confirmPassword) {
        setSaveButtonDisabled(false);
      }
    }
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (newPassword !== event.target.value) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }

    if (newPassword !== event.target.value || !passwordValid) {
      setSaveButtonDisabled(true);
    } else {
      if (event.target.value) {
        setSaveButtonDisabled(false);
      }
    }
  };
  //endregion React Hook (useEffect)

  //region Handlers
  const handleClose = () => {
    props.handleClose();
  };

  const handleIsValid = (isValid) => {
    setPasswordValid(isValid);
  };

  const handleButtonClick = () => {
    const variables = {
      currentPassword: currentPassword,
      newPassword: newPassword
    };
    changePassword({
      variables: variables
    }).then();
  };
  //endregion Handlers

  return (
    <Stack spacing={2} sx={{
      height: "100%"
    }}>
      <Grid item xs={12}>
        <Stack>
          <PasswordInput
            placeholder={gettext("Current Password")}
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
          />
        </Stack>
        <ForgotPasswordLink/>
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <PasswordInput
            placeholder={gettext("New Password")}
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
        </Stack>
        <PasswordValidator password={newPassword} handleIsValid={handleIsValid}/>
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <PasswordInput
            placeholder={gettext("Confirm Password")}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Stack>
        {confirmPasswordError && (
          <FormHelperText error id="accountId-error">
            {gettext("Confirm password does not match.")}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox/>}
          label={gettext("Require all devices to sign in again with the new password.")}
          sx={{
            color: "grey.600"
          }}
        />
      </Grid>

      <Grid item xs={12} textAlign="center">
        <LoadingButton
          variant="contained"
          loading={loading}
          fullWidth
          onClick={handleButtonClick}
          disabled={saveButtonDisabled}
        >
          {gettext("Change Password")}
        </LoadingButton>
      </Grid>
    </Stack>
  );
};

ChangePasswordForm.propTypes = {
  handleClose: PropTypes.func.isRequired
};