import {formatDateBySegmentNoYear, formatDateYear} from "../";

export const toBenchmarkData = (shortSegment, data, addTotal = false) => {
  let returnData = [];
  let totals = {};

  // console.log(data);

  data.forEach((yearData) => {
    yearData.data.forEach((row) => {
      const date = formatDateBySegmentNoYear(row.ts, shortSegment);
      const year = formatDateYear(row.ts);

      // console.log(date, row.ts);


      // Find if there's already an entry for this date
      let existingEntry = returnData.find(entry => entry.ts === date);

      if (existingEntry) {
        // If entry exists, add the year data to it
        existingEntry[year] = row.consumptionV;
      } else {
        // If entry does not exist, create a new one
        let rowData = {
          id: date,
          label: date,
          ts: date
        };
        rowData[year] = row.consumptionV;
        returnData.push(rowData);
      }

      // Add to totals
      if (!totals[year]) {
        totals[year] = 0;
      }
      totals[year] += row.consumptionV;
    });
  });

  // Create the "Total" row
  if (addTotal) {
    let totalRow = {
      id: "Total",
      label: "Total",
      ts: "Total"
    };
    for (const year in totals) {
      totalRow[year] = totals[year];
    }
    returnData.push(totalRow);
  }


  return returnData;
};