export const getPreviousYearDate = (dateStr) => {
  // Parse the input date string
  const date = new Date(dateStr);

  // Subtract one year from the date
  date.setFullYear(date.getFullYear() - 1);

  // Format the date back to the initial format with the correct time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};