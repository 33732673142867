import moment from "moment/moment";
import {capitalizeFirstLetter} from "../string";

export const formatDateDayMonth = (date) => {
  // return capitalizeFirstLetter(moment(date).format("DD MMM YYYY"));

  const formattedDate = moment(date).format("DD MMM");
  const [day, month] = formattedDate.split(" ");
  const capitalizedMonth = capitalizeFirstLetter(month);
  return `${day} ${capitalizedMonth}`;
};
