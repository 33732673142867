import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {ADD_CUSTOMER_USER} from "./commands";

/**
 * Custom hook to add a user to a company.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{addCustomer: Function, loading: boolean}} - The function to add a customer and the loading state.
 */
export function useAddUserCompany(onCompleted = () => {
}, onError = () => {
}) {
  const [comAddCustomerUser, {loading}] = useMutation(ADD_CUSTOMER_USER, {
    onCompleted: onCompleted,
    onError: onError,
    refetchQueries: ["getUserCustomers"]
  });

  const addCustomer = useCallback(
    variables => {
      comAddCustomerUser({
        variables
      });
    },
    [comAddCustomerUser]
  );

  return {
    addCustomer,
    loading
  };
}
