import {formatDateBySegment} from "../dates";

export const meterApiDataToBarChartData = (apiData, segment) => {
  const returnData = [];
  apiData.forEach((arrayItem) => {
    const label = formatDateBySegment(arrayItem.ts, segment);

    let subObj = {
      label: label,
    };
    subObj = {
      ...arrayItem,
      ...subObj,
    };

    returnData.push(subObj);
  });

  return returnData;
};
