import {useEffect, useState} from "react";
import {useTimeout} from "./";

/**
 * Custom hook to debounce a value.
 * @param {*} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds.
 * @returns {*} - The debounced value.
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const {
    reset,
    clear
  } = useTimeout(() => setDebouncedValue(value), delay);
  useEffect(reset, [value, reset]);
  useEffect(clear, [clear]);

  return debouncedValue;
};
