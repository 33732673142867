import React from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {
  ComponentsOverrides as AbsoluteSightComponentsOverrides,
  ThemeOptions as AbsoluteSightThemeOptions
} from "./absolute-sight";
import {
  ComponentsOverrides as EnergiTeamComponentsOverrides,
  ThemeOptions as EnergiTeamThemeOptions
} from "./energiteam";
import {ComponentsOverrides as FabekeComponentsOverrides, ThemeOptions as FabekeThemeOptions} from "./fabeke";
import {useLang} from "./../";
// import { daDK as dataGridDaDK, deDE as dataGridDeDE, enUS as dataGridEnUS } from "@mui/x-data-grid";
// import { daDK as dataGridDaDK, deDE as dataGridDeDE, enUS as dataGridEnUS } from "@mui/material/locale";
import {
  daDK as coreDaDK,
  deDE as coreDeDE,
  enUS as coreEnUS,
  esES as coreEsES,
  frFR as coreFrFR,
  nbNO as coreNbNO,
  nlNL as coreNlNL,
  svSE as coreSvSE
} from "@mui/material/locale";
import {getPartnerKey} from "../../../utils";
import PropTypes from "prop-types";

/**
 * MaterialThemeProvider component to manage theme and localization.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactElement} - The MaterialThemeProvider component.
 */
export default function MaterialThemeProvider(props) {
  const partnerKey = getPartnerKey();
  const {children} = props;
  const locale = useLang();

  // let themeOptionsDefault = defaultThemeOptions();
  // let ComponentsOverrides = defaultComponentsOverrides;

  let themeOptionsDefault = FabekeThemeOptions();
  let ComponentsOverrides = FabekeComponentsOverrides;

  switch (partnerKey) {
    case "energiteam":
      themeOptionsDefault = EnergiTeamThemeOptions();
      ComponentsOverrides = EnergiTeamComponentsOverrides;
      break;
    case "fabeke":
      themeOptionsDefault = FabekeThemeOptions();
      ComponentsOverrides = FabekeComponentsOverrides;
      break;
    case "absolute-sight":
      themeOptionsDefault = AbsoluteSightThemeOptions();
      ComponentsOverrides = AbsoluteSightComponentsOverrides;
      break;
    case "default":
    default:
      themeOptionsDefault = FabekeThemeOptions();
      ComponentsOverrides = FabekeComponentsOverrides;
      // themeOptionsDefault = defaultThemeOptions();
      // ComponentsOverrides = defaultComponentsOverrides;
      break;
  }

  let selectedLanguage = coreEnUS;
  switch (locale) {
    case "da":
      selectedLanguage = coreDaDK;
      break;
    case "de":
      selectedLanguage = coreDeDE;
      break;
    case "es":
      selectedLanguage = coreEsES;
      break;
    case "fr":
      selectedLanguage = coreFrFR;
      break;
    case "nb":
      selectedLanguage = coreNbNO;
      break;
    case "nl":
      selectedLanguage = coreNlNL;
      break;
    case "no":
      selectedLanguage = coreNbNO;
      break;
    case "sv":
      selectedLanguage = coreSvSE;
      break;
    default:
      selectedLanguage = coreEnUS;
      break;
  }

  const themeOptions = React.useMemo(() => (themeOptionsDefault), [themeOptionsDefault]);

  const theme = createTheme(
    themeOptions,
    // locale === "de" ? dataGridDeDE : locale === "da" ? dataGridDaDK : dataGridEnUS,
    locale === selectedLanguage
  );

  theme.components = ComponentsOverrides(theme);

  return (
    <ThemeProvider theme={theme}>
      {" "}
      {children}
    </ThemeProvider>
  );
}


MaterialThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};